import React from "react";
import "./EventHome.css";
import EventHomeImage from "../../common/Images/EventHome.png"
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const EventHome = () => {
  return (
    <div>
       <Row style={{marginLeft: "0px", marginRight: "0px" , height:"100%"}}>
       <Col sm={5}style={{background:"#010672"}} >
        
       <div className="event-story-detail">
          <div className="event-story-heading">
          Event Management
          </div>
          <div className="event-story-para">
          Outdoors and Out of home activities Are important in marketing & brand building. More the visibility leads to more conversion. Outdoor space like any other can be Expensive and has to be creatively used!
Showcase the brand & build the following
          </div>
          {/* <div className="event-story-para">
            if you make it to event, congratulations and welcome. we have a lot
            of things planned for you.
          </div> */}
        </div>
        
        </Col>
        <Col sm={7}>
        <img className="EventImg"  src={EventHomeImage}/>
          
          </Col>
        
      </Row>
    </div>
  );
};

export default EventHome;
