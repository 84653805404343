import React from "react";
import "./ourClients.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TrueMedix from "../../common/Images/ClientLogo/TrueMedix.png";
import FreshKnots from "../../common/Images/ClientLogo/FreshKnots.png";
import RSLEGAL from "../../common/Images/ClientLogo/RSLEGAL.png";
import splendore from "../../common/Images/ClientLogo/splendore.png";

const OurClients = () => {
  return (
    <div className="OurClientBg-wrapper">
      <div>
        <h1 className="Heading">Our Clients</h1>
      </div>
      <Row>
        <Col xs={6}>
          <div class="cards">
            <div class="card">
              <img src={TrueMedix} />
            </div>
            <div class="card">
              <img src={RSLEGAL} />
            </div>
            <div class="card">
              <img src={splendore} />
            </div>
            <div class="card">
              <img src={FreshKnots} />
            </div>
          </div>
        </Col>
        <Col xs={6} style={{textAlign:"-webkit-center"}}>
          <div className="clientTestimonial">
            <Row>
              {/* <Col xs={2}> </Col> */}
              <Col xs={6}>
                {" "}
                <h6 className="clientName">Prachi agarwal</h6>
              </Col>
              <Col style={{ textAlign: "center" }} xs={5}>
              <img src={FreshKnots} />
              </Col>
              {/* <Col xs={2}> </Col> */}
            </Row>

            <h5 className="clientDis">“It has been a great experience taking services from Growth Mind. Their response time is great and they work hard to provide customer satisfaction. Really happy with the services.“</h5>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default OurClients;
