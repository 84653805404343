import React from "react";
import Button from "../../common/Button";
import "./Events.css";


const Events = () => {
  return (
    <div className="Event-hero-section-wrapper ">
      {/* <div className="flex absolute-center hero-claim-label">
        <div>pay credit card bill. earn guaranteed ₹200 back.</div>
        <div className="claim-anchor">
          claim now
          <img
            src="https://web-images.credcdn.in/_next/assets/images/home-page/arrow.png"
            className="claim-arrow"
          />
        </div>
      </div> */}
      <div className="flex flex-col absolute-center hero-section max-width">
        <div className="hero-heading">
        Event <br/> Management
        </div>
        {/* <div className="hero-subheading">
        Your search for the best events in town ends here!
        </div> */}
        {/* <Button buttonText="Join the club" /> */}
      </div>
    </div>
  );
};

export default Events;
