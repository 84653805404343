import React from "react";
import HomePage from "./pages/homePage";
import { BrowserRouter as Router, Route } from "react-router-dom";
import EventPage from "./pages/eventPage";
import UiUX from "./pages/UiUx";
import Photography from "./pages/Photography";
import ContactUs from "./components/common/ContactUs";
import MarketingPage from "./pages/marketingPage";
import AboutUs from "./pages/aboutUs";

const App = () => {

  

  return  <div>
     
    <Router>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/events" component={EventPage} />
        <Route exact path="/ui-ux" component={UiUX} />
        <Route exact path="/photography" component={Photography} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/marketing" component={MarketingPage} />
      </Router>
      
  </div>
 
};

export default App;
