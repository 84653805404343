import React from "react";
import Button from "../common/Button";
import "./heroSection.css";

const HeroSection = () => {
  return (
    <div className="home-hero-section-wrapper">
      {/* <div className="flex absolute-center hero-claim-label">
        <div>pay credit card bill. earn guaranteed ₹200 back.</div>
        <div className="claim-anchor">
          claim now
          <img
            src="https://web-images.credcdn.in/_next/assets/images/home-page/arrow.png"
            className="claim-arrow"
          />
        </div>
      </div> */}
      <div className="flex flex-col absolute-center home-hero-section max-width">
        <div className="home-hero-heading">
        Your Growth Partner
        </div>
        <div className="home-hero-subheading">
        WE BUILD IMPACTFUL BRANDS
        </div>
        {/* <Button buttonText="Join the club" /> */}
      </div>
      
    </div>
  );
};

export default HeroSection;
