import React from "react";

import "./PhotoVideographyHome.css";


const PhotoVideographyHome = () => {
 
  return (

    <div className="PhotoVideographyHome">

     

<div > 
  <h1 className="Heading">Photo & Videography</h1>
</div>

      <div style={{paddingTop: "5%", paddingBottom:"10%"}}>
        <div class="row" style={{paddingBottom: "1.2%"}}>
          <div class="columns small-12 medium-6">
            <div class="box1" id="contentToPrint">
            
            </div>
          </div>

          <div class="columns small-12 medium-6">
            <div class="box2">
             
            </div>
          </div>
        </div>

        <div class="row">
          <div class="columns small-12 medium-6">
            <div class="box3">
              
            </div>
          </div>

          <div class="columns small-12 medium-6" >
            <div class="box4">
              <div className="innerbox">
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoVideographyHome;
