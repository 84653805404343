import React from "react";
import AboutUsMain from "../components/AboutUs";
import Footer from "../components/common/footer";
import Header from "../components/common/Header";
import LetsStart from "../components/common/LetsStart/LetsStart"




const AboutUs = () => {
  return (
    <>
      <Header />
      <AboutUsMain/>
      
      <LetsStart/>
      <Footer/>
      
    </>
  );
};

export default AboutUs;
