import React from "react";
import "./seo.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SeoIcon from "../../../common/Images/SeoIcon.png";



const Seo = () => {
  return (
    <div className="SeoBg-wrapper">
      <div className="mainHeading">
        <h1 className="SeoHeading">SEO Marketing</h1>
        <h3 className="subSeoHeading">Know what is the search trend <br/> and we will help you with below</h3>
      </div>
      <Row style={{margin:"0px"}}>
        <Col xs={6}>
          <div class="cards">
            <div class="card">
            <h3>Search Trend</h3>
            </div>
            <div class="card">
            <h3>Top in Search Results</h3>
            </div>
            <div class="card">
            <h3>Relevant</h3>
            </div>
            <div class="card">
            <h3>Free Conversion</h3>
            </div>
          </div>
        </Col>
        <Col xs={6} style={{textAlign:"center"}} >
          <div>
            <img src={SeoIcon} style={{width:"50%"}} />
            <h3 >It all starts <br/> with a word typed in small box</h3>
            
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Seo;
