import React, { useRef, useState, useEffect } from "react";

import "./productShowcase.css";


const ProductShowcase = () => {
  const [showAnimation, setShowAnimation] = useState(false);
  const ref = useRef(null);

  const toggleAnimation = (e) => {
    if (e[0]?.isIntersecting) {
      setShowAnimation(true);
    }
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.5,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(toggleAnimation, options);
    if (!showAnimation) {
      if (ref.current) {
        observer.observe(ref.current);
      }
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  });
  return (
    // <div
    // className={`product-showcase ${showAnimation ? "scale-in-bottom" : ""}`}
    // scale-in-bottom
    // ref={ref}
    // >
    //   {showAnimation && (
    //     <div className="showcase-wrapper">

    //       <a href="/ui-ux">
    //       <img
    //           src={uiux}
    //         className="showcase-ui showcase-mockup-1"
    //       />
    //       </a>

    //      <a href="/events">
    //      <img
    //         src={showcaseEvent}
    //         className="showcase-ui showcase-mockup-3"
    //       />
    //      </a>

    //      <a href="/marketing">
    //      <img
    //           src={marketing}
    //         className="showcase-ui showcase-mockup-5"
    //       />
    //      </a>

    //     </div>
    //   )}
    // </div>

    <div className="product-showcase">
      <div style={{paddingTop: "10%", paddingBottom:"10%"}}>
        <div class="row" style={{paddingBottom: "1.2%"}}>
          <div class="columns small-12 medium-6">
            <div class="Productbox1" id="contentToPrint">
            
            </div>
          </div>

          <div class="columns small-12 medium-6">
            <div class="Productbox2">
             
            </div>
          </div>
        </div>

        <div class="row">
          <div class="columns small-12 medium-6">
            <div class="Productbox3">
              
            </div>
          </div>

          <div class="columns small-12 medium-6" >
            <div class="Productbox4">
              <div className="innerbox">
          
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductShowcase;
