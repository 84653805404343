import React, { useEffect, useState } from "react";
import "./header.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../../../common/Images/logo.png"
import ContactUs from "../ContactUs";

const Header = () => {
  return (
    <div>
      {["xl"].map((expand) => (
        <Navbar key={expand} bg="light" expand={expand} className="mb-3">
          <Container>
            <Navbar.Brand href="#">
            <a href="/"> <img src={Logo}style={{height:"50px",marginRight:"40px"}}  /></a>
            
              <Nav className="justify-content-end flex-grow-1 navList pe-3">
                <NavDropdown
                  title="Services"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}>
                  <NavDropdown.Item href="photography">Photo & VideoGraphy</NavDropdown.Item>
                  <NavDropdown.Item href="ui-ux">Ui/Ux Design & Tech</NavDropdown.Item>
                  <NavDropdown.Item href="events">Event Management</NavDropdown.Item>
                  <NavDropdown.Item href="marketing">Marketing</NavDropdown.Item>

                 
                </NavDropdown>
                

                <Nav.Link style={{paddingLeft:"30px"}} href="/about-us">About Us</Nav.Link>
                {/* <Nav.Link href="#action2">Careers</Nav.Link> */}
              </Nav>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
             
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
               <a href="/"> <img src={Logo}style={{height:"50px",marginRight:"40px"}}  /></a>

                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body style={{justifyContent: "end"}}>
                <Nav className="justify-content-end flex-grow-1 DoNotWant pe-3">
                <NavDropdown
                  title="Services"
                  id={`offcanvasNavbarDropdown-expand-${expand}`}>
                
                  <NavDropdown.Item href="/photography">Photo & VideoGraphy</NavDropdown.Item>
                  <NavDropdown.Item href="/ui-ux">Ui/Ux Design & Tech</NavDropdown.Item>
                  <NavDropdown.Item href="/events">Event Management</NavDropdown.Item>
                  <NavDropdown.Item href="/marketing">Marketing</NavDropdown.Item>

                </NavDropdown>
                <Nav.Link href="/about-us">About Us</Nav.Link>
            
                </Nav>
                <Form className="d-flex">
                  <ContactUs/>
                  {/* <Button className="contactUsBtn">Contact Us</Button> */}
                </Form>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </div>
  );
};

export default Header;
