import React from "react";
import "./TypesOfShoots.css"



const TypesOfShoots = () => {
  return (
   <div>
    <section className="Section1">
        <div className="Section1Text">
            <h2>All that matters is Brand Identity</h2>
            <h4>We don't help you put up an advertisement – we help you create a brand with substance!</h4>
        </div>
    </section>



    <section className="Section2">
        <div className="Section2Text">
            <h2>Weddings are Desires & Dreams</h2>
            <h4>Sometimes planning is overrated - let us take care of your wedding so you can live the moment.</h4>
        </div>
    </section>

    <section className="Section3">
        <div className="Section3Text">
            <h2>Get a picture that reflects the real you </h2>
            <h4>Discover your worth and elegance in one portrait.</h4>
        </div>
    </section>


    <section className="Section4">
        <div className="Section4Text">
            <h2>Take photos of your event and value them</h2>
            <h4>Add remarkable photos to your event streaks with us.</h4>
        </div>
    </section>

   </div>
  );
};

export default TypesOfShoots;
