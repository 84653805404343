import React from "react";
import "../LetsStart/letsStart.css";
import Button from "../Button";
import ContactUs from "../ContactUs";

const LetsStart = () => {
  return (
    <div className="">
      <div className="app-rating flex">
        <div className="flex app-rating-block flex-col">
          <div className="flex">
            <div className="LetsStartHeading">Let’s Get Started</div>
          </div>
          {/* <div className="non-mobile">
          <Button
            prefix={getIosPrefix()}
            buttonText="Download the app"
            customClass="app-rating-button"
          />
        </div> */}
        </div>
        <div className="flex app-rating-block flex-col">
          <div className="flex">
            <div className="LetsStartHeading">
              <div className="photo-section-button">
                {/* <div className={`LetsStartButton-wrapper flex absolute-center`}>
                Join Us
                </div> */}
                <ContactUs style={{height: "65px !important", width: "160px !important",borderRadius: "50px !important" }} />
              </div>
            </div>
          </div>
          {/* <div className="non-mobile">
          <Button
            prefix={getAndroidPrefix()}
            buttonText="Download the app"
            customClass="app-rating-button"
          />
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default LetsStart;
