import React from "react";
import "./MarketingDMP.css";
import $ from "jquery";
import { Row, Col } from "react-bootstrap";
import LeadGenImg from "../../../common/Images/DM1.png";
import DM2 from "../../../common/Images/DM2.png";
import DM3 from "../../../common/Images/DM3.png";
import DM4 from "../../../common/Images/DM4.png";
import DM5 from "../../../common/Images/DM5.png";





const MarketingDMP = () => {
  $(document).ready(function () {
    var contentSection = $(".content-section");
    var navigation = $("marketingNav");

    //when a nav link is clicked, smooth scroll to the section
    navigation.on("click", "a", function (event) {
      event.preventDefault(); //prevents previous event
      smoothScroll($(this.hash));
    });

    //update navigation on scroll...
    $(window).on("scroll", function () {
      updateNavigation();
    });
    //...and when the page starts
    updateNavigation();

    /////FUNCTIONS
    function updateNavigation() {
      contentSection.each(function () {
        var sectionName = $(this).attr("id");
        var navigationMatch = $('marketingNav a[href="#' + sectionName + '"]');
        if (
          $(this).offset().top - $(window).height() / 2 <
            $(window).scrollTop() &&
          $(this).offset().top + $(this).height() - $(window).height() / 2 >
            $(window).scrollTop()
        ) {
          navigationMatch.addClass("active-section");
        } else {
          navigationMatch.removeClass("active-section");
        }
      });
    }
    function smoothScroll(target) {
      $("body,html").animate(
        {
          scrollTop: target.offset().top,
        },
        800
      );
    }
  });

  return (
    <div style={{ backgroundColor: "#0B0E4E" }}>
      <nav className="marketing-nav">
        <h1
          className="non-mobile"
          style={{ paddingLeft: "50px", fontWeight: "800", fontSize: "45px" }}
        >
          Digital <br /> Marketing
        </h1>
        <ul>
          <li>
            <a href="#section1">
              <span class="rect"></span>
              <span class="circle"></span>
              Lead Generation
            </a>
          </li>
          <li>
            <a href="#section2">
              <span class="rect"></span>
              <span class="circle"></span>
              Search Ads
            </a>
          </li>
          <li>
            <a href="#section3">
              <span class="rect"></span>
              <span class="circle"></span>
              Display Ads
            </a>
          </li>
          <li>
            <a href="#section4">
              <span class="rect"></span>
              <span class="circle"></span>
              Video Ads
            </a>
          </li>
          <li>
            <a href="#section5">
              <span class="rect"></span>
              <span class="circle"></span>
              Creatives on us
            </a>
          </li>
        </ul>
      </nav>

      <section id="section1" class="content-section LeadGeneration">
        <Row >
        <Col className="marketingHeight" sm={4}>
          <div className="XYZ"></div>
            <img src={LeadGenImg}  className="DMimg non-mobile" />
          </Col>
          <Col sm={8} className="">
            <h1>
            Business is hard to build. Our expertise is at your support to generate leads 
            </h1>
            <h2>We understand your needs! Lets get started</h2>
          </Col>
          
        </Row>
      </section>

      <section id="section2" class="content-section SearchAds">
      <Row >
      <Col className="marketingHeight" sm={4}>
      <div className="XYZ"></div>
            <img src={DM2}  className="DMimg non-mobile" />
          </Col>
          <Col sm={8} className="">
            <h1>
            Pay per click advertising
            </h1>
            <h2>An excellent way for way for your business to benefit from paid advertising</h2>
          </Col>
          
        </Row>
      </section>


      <section id="section3" class="content-section DisplayAds">
      <Row >
      <Col className="marketingHeight" sm={4}>
            <img src={DM3}  className="DMimg non-mobile" />
          </Col>
          <Col sm={8} className="">
            <h1>
            Publicize Your Business
            </h1>
            <h2>An excellent way for way for your business to benefit from paid advertising</h2>
          </Col>
          
        </Row>
      </section>

      <section id="section4" class="content-section VideoAds">
      <Row >
      <Col className="marketingHeight" sm={4}>
      <div className="XYZ"></div>
            <img src={DM4}  className="DMimg non-mobile" />
          </Col>
          <Col sm={8} className="">
            <h1>
            Business is hard to build. Our expertise is at your support to generate leads 
            </h1>
            <h2>We understand your needs, Let’s get started</h2>
          </Col>
         
        </Row>
      </section>


      <section id="section5" class="content-section CreativesOnUs">
          <Row >
          <Col className="marketingHeight" sm={4}>
          <div className="XYZ"></div>
            <img src={DM5} className="DMimg non-mobile" />
          </Col>
          <Col sm={8} className="">
            <h1>
            An Art by Artists
            </h1>
            <h2>Impress your customers, we got your back with our creativity and your brand identity</h2>
          </Col>
          
        </Row>
      </section>
    </div>
  );
};

export default MarketingDMP;
