import React from "react";
import Footer from "../components/common/footer";
import Header from "../components/common/Header";
import UiUxHeroSection from "../components/UiUxScreen/UiUxHeroSection";





const UiUX = () => {



  return (
    <>
      <Header />
      <UiUxHeroSection/>
     
      <Footer/>
      
    </>
  );
};

export default UiUX;
