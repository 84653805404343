import React from "react";
import Button from "../../common/Button";
import "./MarketingHeroSection.css";


const MarketingHeroSection = () => {
  return (
    <div className="Marketing-hero-section-wrapper ">

      <div className="flex flex-col absolute-center Marketing-hero-section max-width">
        <div className="Marketing-hero-heading">
        Marketing ?
        </div>
        <div className="hero-subheading">
        Lets scoop your marketing
        </div>
        {/* <Button buttonText="Join the club" /> */}
      </div>
    </div>
  );
};

export default MarketingHeroSection;
