import React from "react";
import "./MarketingSMM.css";
import socialMedia from "../../../common/Images/socialMedia.png";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SeoIcon from "../../../common/Images/SeoIcon.png";

const MarketingSMM = () => {
  return (
    <div className="SMMhero-section-wrapper">
      <div >
        <div className="mainHeading">
          <h1 className="SeoHeading">Social Media Marketing</h1>
          <h3 className="subSeoHeading">
          Lets move your Brand and Customers
          </h3>
        </div>
        <Row style={{ margin: "0px" }}>
          <Col xs={6}>
            <div class="cards">
              <div class="card">
                <h3>Increase followers</h3>
              </div>
              <div class="card">
                <h3>Manage Loyalty</h3>
              </div>
              <div class="card">
                <h3>Set Trends</h3>
              </div>
              <div class="card">
                <h3>Generate Leads</h3>
              </div>
            </div>
          </Col>
          <Col xs={6} style={{ textAlign: "center" }}>
            <div>
              <Row style={{marginLeft: "25%"}}>
                <Col> <img className="socialMediaIcons" src={socialMedia} alt="fb" /></Col>
                
              </Row>
           
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MarketingSMM;
