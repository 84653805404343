import React, { useEffect } from "react";
import "./GlimpseOfEvents.css";
import img1 from "../../../common/Images/Shows/img1.jpg"
import img2 from "../../../common/Images/Shows/img2.jpg"
import img3 from "../../../common/Images/Shows/img3.jpg"
import img4 from "../../../common/Images/Shows/img4.jpg"
import img5 from "../../../common/Images/Shows/img5.jpg"
import img6 from "../../../common/Images/Shows/img6.jpg"
import img7 from "../../../common/Images/Shows/img7.jpg"
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger"
import $ from "jquery"


gsap.registerPlugin(ScrollTrigger);

const GlimpseOfEvents = () => {

  useEffect(() => {
    gsap.to('body', { // LoadingAnimation---------------------
      opacity: 1, duration: 1.3,
  }) // /LoadingAnimation---------------------

  $("#codeby a").mouseenter(function () { // HoverAnimation---------------------
      gsap.to('#ImgWrapper', { backgroundColor: "#f0f0f0", })
      gsap.to('#codeby a', { color: "#2e2e2e", })
      gsap.to('#codeby a span', { color: "#000000", })
  });
  $("#codeby a").mouseout(function () {
      gsap.to('#ImgWrapper', { backgroundColor: "#000000", })
      gsap.to('#codeby a', { color: "#e6e6e6", })
      gsap.to('#codeby a span', { color: "#f0f0f0", })
  }); // /HoverAnimation---------------------

  let LandingPageScrollTrigger = gsap.timeline({
      scrollTrigger: {
          trigger: "#ImgWrapper",
          start: "0% 0%",
          end: "100% 0%",
          pin: "#ImgWrapper",
          scrub: 2.2,
      }
  })
  LandingPageScrollTrigger
      .to('#ImgWrapper #img7', { transform: 'translateZ(4500px)', }, 0)
      .to('#ImgWrapper #img6', { transform: 'translateZ(3700px)', }, 0)
      .to('#ImgWrapper #img5', { transform: 'translateZ(3100px)', }, 0)
      .to('#ImgWrapper #img4', { transform: 'translateZ(2800px)', }, 0)
      .to('#ImgWrapper #img3', { transform: 'translateZ(2600px)', }, 0)
      .to('#ImgWrapper #img2', { transform: 'translateZ(2400px)', }, 0)
      .to('#ImgWrapper #img1', { transform: 'translateZ(2200px)', }, 0)
      .from('#codeby a', { y: 130, opacity: 0 }, 0.31)
  }, [])



  return (
    <div className=" ">
  <div id="ImgWrapper">
    <img id="img1" src={img1} alt=""/>
    <img id="img2" src={img2} alt=""/>
    <img id="img3" src={img3} alt=""/>
    <img id="img4" src={img4} alt=""/>
    <img id="img5" src={img5} alt=""/>
    <img id="img6" src={img6} alt=""/>
    <img id="img7" src={img7} alt=""/>

    

    <div id="codeby">
      <a target="_blank">It Was Some Glimpse of Our Events</a>
    </div>


  </div>
    </div>
  );
};

export default GlimpseOfEvents;
