import React from "react";
import Footer from "../components/common/footer";
import Header from "../components/common/Header";
import MarketingDMP from "../components/MarketingScreen/MarketingDMP";
import MarketingHeroSection from "../components/MarketingScreen/MarketingHeroSection";
import MarketingSEO from "../components/MarketingScreen/MarketingSEO";
import MarketingSMM from "../components/MarketingScreen/MarketingSMM";
import OfflineMarketing from "../components/MarketingScreen/OfflineMarketing";
import WhyMarketingImportant from "../components/MarketingScreen/WhyMarketingImportant";
import LetsStart from "../components/common/LetsStart/LetsStart";
import Seo from "../components/MarketingScreen/SEO/seo";




const MarketingPage = () => {
  return (
    <div>
       <Header />
      <MarketingHeroSection/>
      <MarketingDMP/>
      <Seo/>
      {/* <MarketingSEO/> */}
      <MarketingSMM/>
      <OfflineMarketing/>
      {/* <WhyMarketingImportant/> */}
      <LetsStart/>
      <Footer/>
      
    </div>
  );
};

export default MarketingPage;
