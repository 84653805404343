import React from "react";
import Button from "../../common/Button";
import "./PhotographyMain.css";


const PhotographyMain = () => {
  return (
    <div className="Event-hero-section-wrapper ">
      {/* <div className="flex absolute-center hero-claim-label">
        <div>pay credit card bill. earn guaranteed ₹200 back.</div>
        <div className="claim-anchor">
          claim now
          <img
            src="https://web-images.credcdn.in/_next/assets/images/home-page/arrow.png"
            className="claim-arrow"
          />
        </div>
      </div> */}
      <div className="flex flex-col absolute-center hero-section max-width">
        <div className="hero-heading">
        Photography <br/> <span style={{fontSize:"55px"}}>and</span> Videography
        </div>
        <div className="hero-subheading">
        Adding Moments of Life
        </div>
        {/* <Button buttonText="Join the club" /> */}
      </div>
    </div>
  );
};

export default PhotographyMain;
