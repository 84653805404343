import "./ContactUs.css";
import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import Chat from "../../../common/Images/chat.png";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "react-bootstrap/Button";


function ContactUs() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_d3omr4a",
        "template_cpneilr",
        form.current,
        "iUKTA1kSwQZIb37PI"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            Success();
            handleClose();
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Success = () => toast.success("Send successfully");

  return (
    <div>
      <ToastContainer style={{ zIndex: "999999" }} closeOnClick={false} />

      <Button onClick={handleShow} className="contactUsBtn">Contact Us</Button>
      
      <div className="contactUsBg">
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div class="contact-container">
              <div class="contact-left">
                <form ref={form} onSubmit={sendEmail} class="contact-form">
                  <div class="single-row">
                    <input
                      type="text"
                      name="from_firstname"
                      placeholder="First Name"
                      class="form-in mr"
                    />
                    <input
                      type="text"
                      name="from_lastname"
                      placeholder="Last Name"
                      class="form-in ml"
                    />
                  </div>
                  <div class="multiple-row">
                    <input
                      type="email"
                      name="from_email"
                      placeholder="Email"
                      class="form-in"
                    />
                    <textarea
                      placeholder="Your Message"
                      name="from_message"
                      class="form-in"
                    ></textarea>
                    <input type="submit" id="submit" />
                    <label for="submit">
                      <i class="fas fa-paper-plane"></i>
                    </label>
                  </div>
                </form>
              </div>
              <div class="contact-right">
                <h2>Contact Informations</h2>
                <div class="contact-info">
                  <p>
                    <i class="fas fa-phone-alt"></i>
                    <a href="tel:+9697403999" style={{ color: "#fff" }}>
                      +91 9697403999
                    </a>
                  </p>

                  <p>
                    <i class="fas fa-envelope"></i>{" "}
                    growthmind.marketing@gmail.com{" "}
                  </p>
                  <p>
                    <i class="fas fa-map-marker-alt"></i>2nd floor, 371, 5th
                    Main Rd, Sector 6, HSR Layout, Bengaluru, Karnataka 560068
                  </p>
                </div>

                <div class="social-links">
                  <a href="https://www.facebook.com/growthmindsin/">
                    <i class="fab fa-facebook"></i>
                  </a>
                  <a href=" https://www.instagram.com/growthmindin/">
                    <i class="fab fa-instagram"></i>
                  </a>
                  <a href="https://twitter.com/growthmindin/">
                    <i class="fab fa-twitter"></i>
                  </a>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ContactUs;
