import React, { useState, useEffect } from "react";

import "./UiUx.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const UiUxHeroSection = () => {
  useEffect(() => {
    // ---------------------PageA ScrollTrigger---------------------
    const PageAScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageA",
        scroller: "#wrapperUiUx",
        start: "50% 100%",
        end: "50% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageAScrollTrigger.from("#wrapperUiUx #PageA #ParallaxDiv", {
      y: "100%",
      ease: "none",
    }).to("#wrapperUiUx #PageA #ParallaxDiv", { y: "-100%", ease: "none" });

    const PageABGScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageA",
        scroller: "#wrapperUiUx",
        start: "0% 100%",
        end: "100% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageABGScrollTrigger.set("#wrapperUiUx #PageA", {
      backgroundPositionY: "400px",
      ease: "none",
    }).from("#wrapperUiUx #PageA", { backgroundPositionY: "-400px", ease: "none" });
    // ---------------------/PageA ScrollTrigger---------------------

    // ---------------------PageB ScrollTrigger---------------------
    const PageBScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageB",
        scroller: "#wrapperUiUx",
        start: "50% 100%",
        end: "50% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageBScrollTrigger.from("#wrapperUiUx #PageB #ParallaxDiv", {
      y: "100%",
      ease: "none",
    }).to("#wrapperUiUx #PageB #ParallaxDiv", { y: "-100%", ease: "none" });

    const PageBBGScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageB",
        scroller: "#wrapperUiUx",
        start: "0% 100%",
        end: "100% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageBBGScrollTrigger.set("#wrapperUiUx #PageB", {
      backgroundPositionY: "400px",
      ease: "none",
    }).from("#wrapperUiUx #PageB", { backgroundPositionY: "-400px", ease: "none" });
    // ---------------------/PageB ScrollTrigger---------------------

    // ---------------------PageC ScrollTrigger---------------------
    let PageCScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageC",
        scroller: "#wrapperUiUx",
        start: "50% 100%",
        end: "50% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageCScrollTrigger.from("#wrapperUiUx #PageC #ParallaxDiv", {
      y: "100%",
      ease: "none",
    }).to("#wrapperUiUx #PageC #ParallaxDiv", { y: "-100%", ease: "none" });

    let PageCBGScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageC",
        scroller: "#wrapperUiUx",
        start: "0% 100%",
        end: "100% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageCBGScrollTrigger.set("#wrapperUiUx #PageC", {
      backgroundPositionY: "400px",
      ease: "none",
    }).from("#wrapperUiUx #PageC", { backgroundPositionY: "-400px", ease: "none" });


    // page D
    let PageDScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageD",
        scroller: "#wrapperUiUx",
        start: "50% 100%",
        end: "50% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageDScrollTrigger.from("#wrapperUiUx #PageD #ParallaxDiv", {
      y: "100%",
      ease: "none",
    }).to("#wrapperUiUx #PageD #ParallaxDiv", { y: "-100%", ease: "none" });

    let PageDBGScrollTrigger = gsap.timeline({
      scrollTrigger: {
        trigger: "#wrapperUiUx #PageD",
        scroller: "#wrapperUiUx",
        start: "0% 100%",
        end: "100% 0%",
        scrub: 0,
        // markers: "true",
      },
    });
    PageDBGScrollTrigger.set("#wrapperUiUx #PageD", {
      backgroundPositionY: "400px",
      ease: "none",
    }).from("#wrapperUiUx #PageD", { backgroundPositionY: "-400px", ease: "none" });



  }, []);


  

  return (
    <div>
      <div id="wrapperUiUx">
        <div id="HomePage">

        <div className="flex flex-col absolute-center uiUx-hero-section  max-width">
        <div className="ui-hero-heading">
        Impress Your Customers
        </div>
        <div className="ui-hero-subheading">
        UI/UX-focused product solutions: the way to win hearts and minds
        </div>
       
      </div>
          


        </div>
        <div id="PageA">
         
          <h2>
            <span className="uiSub">User Research</span>
            <br/>
            <br/>
            It's all about user research. A user's perspective plays a significant role in determining a product's fit.</h2>
          <div id="ParallaxDiv"></div>
        </div>

        
        <div id="PageB">
        <h2>
            <span className="uiSub">Product Design</span>
            <br/>
            <br/>
            Design is the beginning. A product is more than its features; it is an amalgamation of its design and function.</h2>
          <div id="ParallaxDiv"></div>
        </div>
        <div id="PageC">


          <h2>
            <span className="uiSub">User Testing</span>
            <br/>
            <br/>
            It's all about user research. A user's perspective plays a significant role in determining a product's fit.</h2>


          <div id="ParallaxDiv"></div>
        </div>
        <div id="PageD">
        <h2>
            <span className="uiSub">Product Maturity</span>
            <br/>
            <br/>
            It's all about user research. A user's perspective plays a significant role in determining a product's fit.</h2>
          <div id="ParallaxDiv"></div>
        </div>
      </div>
    </div>
  );
};

export default UiUxHeroSection;
