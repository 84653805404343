import React from "react";
import Button from "../common/Button";
import "./feelSpecial.css";

const FeelSpecial = () => {
  return (
    <div className="feel-special photo-section">
      <div className="max-width">
        <div className="photo-section-child">
          <div className="photo-section-top dynamic-margin">
            <div className="photo-section-heading">
            Mindful Branding
            </div>
            {/* <div className="photo-section-subheading">
              exclusive rewards for paying your bills
            </div> */}
          </div>
          <div className="photo-section-bottom dynamic-margin">
            <div className="photo-section-description">
            "The truly impactful brand engages its stakeholders, inspires them to take action, dreams big and leads them to action, it leaves a lasting impression on its audience and creates genuine influence. The creation of work like this is what we are passionate about.

            </div>
            {/* <div className="photo-section-button">
              <Button buttonText="Explore rewards" />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeelSpecial;
