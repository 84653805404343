import React from "react";
import "./MarketingHome.css";
import marketingHomeImg from "../../common/Images/marketingHome.png"

const MarketingHome = () => {
  return (
    <div className="MarketingHome flex absolute-center">
      <div className="max-width flex story-wrapper">
        <div className="flex cred-story-heading-wrapper">
          <img style={{width: "55%"}} src={marketingHomeImg}/>
        </div>
        <div className="cred-story-detail">
          <div className="cred-story-heading">
          All About Marketing
          </div>
          <div className="cred-story-para">
          There is no denying that digital Will be here for a long time. Being digital & breathing digital brings increased Visibility, marketing opportunities & Customer engagement.
          </div>
          {/* <div className="cred-story-para">
            if you make it to CRED, congratulations and welcome. we have a lot
            of things planned for you.
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default MarketingHome;
