import React from "react";
import "./aboutUs.css";
import AboutUsMainImg from "../../common/Images/aboutMain.png";
import Points from "../../common/Images/5Points.png";

const AboutUsMain = () => {
  return (
    <div>
      <div className="AboutUs-hero-section-wrapper ">
        <div className="flex flex-col absolute-center hero-section max-width">
          <div className="about-hero-heading">
            Our passion never <br /> stops us from doing <br /> better for our
            clients.
          </div>
          <img src={AboutUsMainImg} />
        </div>
      </div>
      <section className="AboutContent">
        <div className="AboutContentDiv">
          <h4>Tag to growth with Growth Mind</h4>
          <br />
          <br />
          <h6>
            Growth Mind believes in the digital frequency for the advanced
            generation. Our experience is not just about creating digital
            content. We design it differently, creatively, and passionately. We
            opt to focus on more than one aspect of your Brand Digital
            Marketing, Website development, Social media marketing, Video
            production and Photography.
            <br />
            <br />
            Our goal is to create an open opportunity for your brand and invoke
            better communication between you and your brand identity. Get
            connected with us through email or call and initiate to create
            something super-awesome.
          </h6>
        </div>

        <div style={{ paddingLeft: "10%" }}>
          <h4>5 Things We Value</h4>
        </div>
        <img className="AboutContentDiv" src={Points} />
      </section>
    </div>
  );
};

export default AboutUsMain;
