import React from "react";
import AppRating from "../components/AppRating";
import BrandsLove from "../components/BrandsLove";
import Footer from "../components/common/footer";
import Header from "../components/common/Header";
import CredExperience from "../components/CredExperience";
import CredSecurity from "../components/CredSecurity";
import CredStory from "../components/EventHome";
import FeelSpecial from "../components/FeelSpecial";
import HeroSection from "../components/HeroSection";
import MobileScroll from "../components/MobileScroll";
import ProductShowcase from "../components/ProductShowcase";
import WindowPeak from "../components/WindowPeak";
import MarketingHome from "../components/MarketingHome";
import PhotoVideographyHome from "../components/Photo&Videography";
import OurClients from "../components/OurClients/ourClients";
import LetsStart from "../components/common/LetsStart/LetsStart";

const HomePage = () => {
  return (
    <>
      <Header />
      <HeroSection />
      <ProductShowcase />
      <FeelSpecial />
       {/* <BrandsLove /> */}
      {/* <CredExperience />  */}
      <MarketingHome/>
      

      {/* <MobileScroll /> */}
      <WindowPeak />
      <CredSecurity />
      <CredStory />
      <PhotoVideographyHome/>
      <OurClients/>
      {/* <AppRating /> */}
      <LetsStart/>
    
      <Footer />
    </>
  );
};

export default HomePage;
