import React from "react";
import Footer from "../components/common/footer";
import Header from "../components/common/Header";
import LetsStart from "../components/common/LetsStart/LetsStart"
import PhotographyMain from "../components/PhotographyScreens/PhotographyMain";
import GlimpseOfPhotos from "../components/PhotographyScreens/GlimpseOfPhotoWork";
import TypesOfShoots from "../components/PhotographyScreens/TypesOfShoots";





const Photography = () => {
  return (
    <>
      <Header />
      <PhotographyMain/>
      <GlimpseOfPhotos/>
      <TypesOfShoots/>
      <LetsStart/>
      <Footer/>
      
    </>
  );
};

export default Photography;
