import React from "react";
import Footer from "../components/common/footer";
import Header from "../components/common/Header";
import Events from "../components/EventScreen/Events";
import GlimpseOfEvents from "../components/EventScreen/GlimpseOfEvents";
import TypesOfEvents from "../components/EventScreen/TypesOfEvents";
import LetsStart from "../components/common/LetsStart/LetsStart"




const EventPage = () => {
  return (
    <>
      <Header />
      <Events/>
      <GlimpseOfEvents/>
      <TypesOfEvents/>
      <LetsStart/>
      <Footer/>
      
    </>
  );
};

export default EventPage;
