import React from "react";
import "./windowPeak.css";

const WindowPeak = () => {
  return <div className="window-peak">

<div className="flex flex-col absolute-center hero-section max-width">
        <div className="hero-heading" style={{color:"#811ab1"}}>
        Design language at work
        </div>
        <div className="hero-subheading" >
        UI / UX
  
        </div>
       
      </div>
  </div>;
};
// 2e29b3
export default WindowPeak;
