import React from "react";
import Button from "../common/Button";
import "./credSecurity.css";

const CredSecurity = () => {
  return (
    <div className="cred-security photo-section">
      <div className="max-width">
        <div className="photo-section-child" style={{ paddingTop: "200px" }}>
          <div className="photo-section-top dynamic-margin">
            <div
              className="photo-section-heading"
              // style={{color:"#2e29b3"}}
              style={{ color: "#35F5C6", lineHeight: "90px" }}
            >
              UI / UX is an art We are the Artists
            </div>
            {/* <div className="photo-section-subheading">
              what’s yours remains only yours.
            </div> */}
          </div>
          <div className="photo-section-bottom dynamic-margin">
            <div
              className="photo-section-description"
              style={{ color: "#fff" }}
            >
              Get mobile app designed to work Like a magic for you and customers
              We define & build your business on the Go with latest
              technologies. Your purpose is to monetise and ours Is to make it
              happen by our design Oriented solutions
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CredSecurity;
