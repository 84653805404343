import React, { useEffect } from "react";
import "./TypeOfEvents.css";

import { gsap, ScrollTrigger } from "gsap/all";

// import {ScrollTrigger} from "gsap/ScrollTrigger"
import {Observer} from "gsap/Observer";
// import {gsap} from "gsap";


gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Observer);



const TypesOfEvents = () => {


  useEffect(() => {
    const containers = document.querySelectorAll(".div-container");

    const text_elem = document.querySelectorAll(".text",".subText");
    
    
    for (let i = 0; i < text_elem.length; i++) {
      gsap.to(text_elem[i], {
        scrollTrigger: {
          trigger: containers[i],
          start: "top 20%",
          toggleActions: "play reset play reset"
        },
        filter: "blur(0)",
        duration: 0.6,
        
        display: "block",
        yoyo: "true"
      });
    }
    
  

}, []);

  return (
    <div className="">


  
<section class="div-container" id="section-beaches">
    <div class="parallax parallax1">
      <div id="div-span-beaches" style={{textAlign:"left"}} class="text">
      All that matters is brand identity.
        <br/>
        <br/>

        <span style={{fontSize:"20px"}}>
        We don't help you put up an advertisement – we help you create a brand with substance!
        </span>
      </div>
    
    </div>
  </section>

  <section class="div-container" id="section-civilization">
    <div class="parallax parallax2">
    <div id="div-span-beaches" style={{textAlign:"left"}} class="text">
    Weddings are Desires & Dreams
        <br/>
        <br/>
        <span style={{fontSize:"20px"}}>
        Sometimes planning is overrated - let us take care of your wedding so you can live the moment.
        </span>
      </div>
    </div>
  </section>

  <section class="div-container" id="section-places">
    <div class="parallax parallax3">
    <div id="div-span-beaches" style={{textAlign:"left"}} class="text">
    The mood is all it takes
        <br/>
        <br/>
        <span style={{fontSize:"20px"}}>
        When it comes to the best events, we are the first name that comes to mind!</span>
      </div>
     
    </div>


  </section>
  <section class="div-container" id="section-places">
    <div class="parallax parallax4">
    <div id="div-span-beaches" style={{textAlign:"left"}} class="text">
    A plan which will leave you in awe!
        <br/>
        <br/>
        <span style={{fontSize:"20px"}}>
        Host your unforgettable events in our spacious halls and enjoy the view of beautifully landscaped lawns!
        </span>
      </div>
    </div>
  </section>
  
  
  

    </div>
  );
};

export default TypesOfEvents;
